import React from 'react';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import MassiveHeader from './massiveHeader';
import Subtitle from './subtitle';
import heroStyle from './hero.module.css';
import PrimaryLinkButton from '../components/PrimaryLinkButton'
import { Link } from 'gatsby';
import S from '../pages/button.module.css'

interface HeroProps {
    title: string,
    subtitle: string
};

const style = {
    backgroundColor: 'hsl(243, 94%, 66%);',
    color: 'white',

}

// A hero component is a very large heading with a description. 
// A hero should be used as the main heading on a page. 
// Typically used for branding purposes to 
// 1. State the brand name and 2. State a tagline.
const Hero = (props: HeroProps) => {
    return (
        <div className={heroStyle.hero}>
            <Grid container direction="column" alignItems="center">
                <Grid item>
                    <MassiveHeader text={props.title}/>
                </Grid>
                <Grid item>
                    <Subtitle text={props.subtitle} />
                </Grid>
                <Grid item>
                    <PrimaryLinkButton text="contact" to="/contact/"/>
                </Grid>
            </Grid>
        </div>
       
    );
};

export default Hero;
