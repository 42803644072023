import React from 'react';
import Grid from '@material-ui/core/Grid';
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import S from './ihtCard.module.css'

const IHTCard = (props) => {
    return (
        <Grid item md={4}>
            <Card className={S.card}>
                <CardMedia 
                    image={props.image}
                    className={S.cardmedia}
                />
                <CardContent>
                    <h3 className="cardHeader">{props.title}</h3>
                    <p className="secondaryText">{props.description}</p>
                </CardContent>
            </Card>
        </Grid>
    );
};

export default IHTCard;
