import React from 'react';
import subtitleStyle from './subtitle.module.css';

interface SubtitleProps {
    text: string
};

const Subtitle = (props: SubtitleProps) => {
    return (
        <p className={subtitleStyle.subtitle}>{props.text}</p>
    );
};

export default Subtitle;