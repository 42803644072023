import React from 'react';
import Container from '@material-ui/core/Container';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Hero from '../components/hero';
import Navbar from '../components/navbar';
import SEO from '../components/seo';
import S from './about.module.css';
import HomeLogo from '../images/undraw_sweet_home_dkhr.svg'
import HackerMind from '../images/hacker_mind.svg';
import MindMap from '../images/mind_map.svg';
import CodeReview from '../images/code_review.svg';
import CloudHosting from '../images/cloud_hosting.svg';
import ProductIteration from '../images/product_iteration.svg';
import ImageHeadingText from '../components/imageHeadingText';
import IHTCard from '../components/ihtCard';
import DefinitionCard from '../components/DefinitionCard';


const Home = () => {
    return (
        <div>
            <SEO 
                title="Home"
                description="Tacent homepage"
            />
            <Container style={{padding: '20px'}} maxWidth='lg'>
                <Navbar />
                <Hero 
                    title="Tacent" 
                    subtitle="Your application security partner."
                />
                <div className={S.largesection}>
                    <h2 className={S.mh2}>What we do</h2> 
                    <Grid spacing={6} justify='center' container>
                        <IHTCard image={HackerMind} title="Penetration Testing" description="See how your applications stack up against industry leading security professionals."/>
                        <IHTCard image={CloudHosting} title="Cloud Security" description="Assess your cloud environments for security misconfigurations, or help architect them."/>
                        <IHTCard image={MindMap} title="Security Architecture" description="We'll help design solutions with you to ensure security is considered from the beginning." />
                        <IHTCard image={CodeReview} title="Code Reviews" description="Eyes on your code that understand security can identify even the most complicated vulnerabilities." />
                        <IHTCard image={ProductIteration} title="DevSecOps" description="Implement security process into your development lifecycle to address vulnerabilities at the root."/>
                    </Grid>

                </div>
            </Container>
        </div>
    );
};

export default Home;
