import React from 'react';
import {Link} from 'gatsby';
import S from './PrimaryLinkButton.module.css'

const PrimaryLinkButton = (props) => {
    return (
        <Link className={S.primaryButtonLink} to={props.to}>{props.text}</Link>
    )
}

export default PrimaryLinkButton;
