import React from 'react';
import massiveHeaderStyles from './massiveHeader.module.css';
import { Typography } from '@material-ui/core';

interface MassiveHeaderProps {
    text: string
};

const MassiveHeader = (props: MassiveHeaderProps) => {
    return (
        <h1 className={massiveHeaderStyles.massive}>{props.text}</h1>
    );
};

export default MassiveHeader;